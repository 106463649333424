<template>
  <div>
  <com-mon>
    <div class = "topbox flexRow">
      <img :src="imgurl+'/image/upgradeservice-titleImg.png'" style="width:46px;height:46px" alt="">
      <div class = "titflexCol" style="margin-left:5px">
        <div class = "titlefont">升级服务</div>
        <div class = "contentfont" >
          <span>
            当客户需要长期或多样的服务时，接待人员可发送专员名片，邀请客户添加服务专员为好友，享受专员固定的长期服务；也可以发送客户群二维码给客户，
            邀请客户加入客户群，享受群内更丰富多样的服务
          </span>
        </div>
      </div>
    </div>
    <el-card style="margin-top:10px">
      <div style="margin:10px 0px 30px 0px">
        <div style="display:inline-block">
          <div class = "headline flexRow">
            <div class = "circularTile flexRow" style="justify-content: center;">1</div>
            <div class = "headlineFont">管理员在企业微信管理后台「应用管理 -微信客服-升级服务」中，配置专员和客户群。</div>
          </div>
        </div>
        <div class = "flexRow" style="align-items:flex-start">
          <img :src="imgurl + '/image/upgradeservice-first1.png'" style="width:600px;height:480px" alt="">
          <img :src="imgurl + '/image/upgradeservice-first2.png'" style="width:600px;height:480px;margin-left:37px" alt="">
        </div>
      </div>
      <div style="margin:10px 0px 30px 0px">
        <div style="display:inline-block">
          <div class = "headline flexRow">
            <div class = "circularTile flexRow" style="justify-content: center;">2</div>
            <div class = "headlineFont">接待人员服务客户时可点击右上角按钮，选择为客户升级为专员或客户群服务。</div>
          </div>
        </div>
        <div class = "flexRow" style="justify-content:space-between;align-items:flex-start">
          <img :src="imgurl + '/image/upgradeservice-second1.png'" style="width:850px;height:495px" alt="">
        </div>
      </div>
      <div style="margin:10px 0px 30px 0px">
        <div style="display:inline-block">
          <div class = "headline flexRow">
            <div class = "circularTile flexRow" style="justify-content: center;">3</div>
            <div class = "headlineFont">确定后，将随机发送一名专员的企业微信名片或一个客户群的二维码给客户</div>
          </div>
        </div>
        <div class = "flexRow" style="justify-content:space-between;align-items:flex-start">
          <img :src="imgurl + '/image/upgradeservice-third1.png'" style="width:850px;height:495px" alt="">
        </div>
      </div>
    </el-card>
  </com-mon>
  </div>
</template>

<script>
import Commom from "@/views/customerServe/component/Common.vue" //引入组件
import config from "@/config"
export default{
  components:{
   	"com-mon": Commom
  },
  data(){
    return{
      imgurl:config.IMG_BASE,
    }
  }
}
</script>

<style lang="less" scoped>
.topbox{
  padding:10px 20px;
  background:white;
  .titflexCol{
    display: flex;
    flex-direction: column;
  }
  .titlefont{
    font-size: 14px;
    color: #303133;
    /* line-height: 1.5; */
    font-weight: bold;
    margin-top: 2px;
  }
  .contentfont{
    font-size: 13px;
    color: #606266;
    /* line-height: 1.5; */
    margin-top: 10px;
  }
}
.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
}
.headline{
  float: left;
  background: rgba(64, 158, 255,.1);
  border-radius: 50px;
  padding: 2px;
  // width: 900px;
  .circularTile{
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background: #409eff;
    font-size: 16px;
    color: white;
    // margin-left: 10px;
  }
  .headlineFont{
    font-size: 14px;
    padding: 0px 80px 0px 10px;
    color: #606266;
  }
}
</style>
 
